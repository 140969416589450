<template>
    <div>
        <Carregamento v-if="!live"/>
        <div v-else>
            <section id="containerLive">
                <div class="container py-5">
                    <Caroussel class="mb-0"/>

                    <div class="row justify-content-center align-items-center">
                        <div class="col col-12">
                            <Video />
                        </div>
                        <div class="col">
                            <Chat />
                        </div>

                        <div class="col d-none">
                            <p class="text1">
                                Clique abaixo para<br>
                                enviar suas dúvidas:
                            </p>
                            <a href="https://form.jotform.com/242033062961651" target="_blank" class="submitBtn">ENVIAR PERGUNTA</a>
                            <p class="text2">
                                As respostas serão enviadas pelo Time Petronas para o e-mail cadastrado.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="live">
                <Footer :lang="lang"/>
            </div>
        </div>
    </div>
</template>



<script>
    import { mapState } from 'vuex';
    import {api} from '@/services.js'
    import Video from '@/components/Live/Video.vue'
    import Footer from '@/components/Live/Footer.vue'
    import Caroussel from '@/components/Live/Carousel.vue'
    import Chat from '@/components/Live/Chat.vue'

    export default {
        props: ['id','lang'],
        name: "Live",
        components: {
            Caroussel,
            Chat,
            Video,
            Footer,
        },
         data() {
            return {
                btnStyles: {},
                state: null
            }
        },
        computed: {
            ...mapState(["live", "login"])
        },
        methods: {
            getLive() {
                this.$store
                    .dispatch('getLive')
                    .then(() => {
                        // if(this.live.waiting[`${this.lang}`]=='true' || this.live.waiting[`${this.lang}`]) {
                        //     this.$router.push(`/waiting/${this.lang}`)
                        // }
                         if(this.live.login.config.state == 'public') {
                            this.state = "public"
                        }
                        this.setStyle();
                        this.checkToken()
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            setStyle() {
                let obj = this.$store.state.live;
                let prefix = obj.live.body;

                document.body.style.cssText =
                    `background: url(${prefix.styles.backgroundImage}) ${prefix.styles.backgroundColor};`;

                this.btnStyles = prefix.button;

            },
            logOut(){
                window.localStorage.removeItem("token");
                this.$store.commit("UPDATE_LOGIN", false);
                this.$router.push(`/${this.lang}/`);
            },
            checkToken(){
                if(this.live.login.config.state == "public"){
                    null
                } else if(this.live.login.config.state == "private" && this.$route.params.lang != "en") {
                //    !window.localStorage.getItem("token") ? this.$router.push(`/login/${this.lang}`) : null
                    if(window.localStorage.getItem("mail")) {
                        var item = {
                            liveId: this.live.id,
                            lang: this.lang,
                            email: window.localStorage.getItem("mail"),
                            originBy: 'Live'
                        }

                        api.login(item).then(res => {
                            if(res.data.code!=1) {
                                this.$gtag.event('User not able to this live lang', { method: 'Google' })
                                this.$router.push(`/login/${this.lang}`)
                            }
                        })
                    }else {
                        this.$router.push(`/login/${this.lang}`)
                    }
                }
            },
            setLang(){
                this.$route.params.lang ? this.$store.commit('UPDATE_LANG', this.$route.params.lang) : this.$store.commit('UPDATE_LANG', 'pt')
                // if(this.$route.params.lang!='pt') {
                //     this.$router.push(`/login/pt`)
                // }
            }
        },
        created() {
            this.setLang()
            this.getLive()
        }
    }
</script>

<style scoped>
.submitBtn{
    color: #fff;
    background-color: #1ea69b;
    border-radius: 10px;
    padding: 6px 20px 8px 20px;
    text-decoration: none;
}

.text1{
    color: #fff;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 16px;
}

.text2{
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    margin-top: 16px;
}

#containerLive {
    min-height: calc(100vh - 74px);
}
.live {
  
}
.live .header {
  background: #5ea69b;
  height: 200px;
}
@media (max-width: 991px) {
  .live .header {
    height: 180px;
  }
}
.live .header .bg {
  background-size: cover;
  background-position: center center;
}

/* .my-card {
    margin: 0;
    padding: 15px;
    border-radius: 0;
    border: none;
} */

.text-big{
    font-family: 'museo-sans-900';
    font-size: 22px;
    line-height: 1.2;
}

.btn-enviarPerguntas{
    font-family: 'museo-sans-900';
    color: #fff;
    background-color: #008178;
    border-radius: 10px;
    padding: 6px 25px 8px 25px;
    text-transform: uppercase;
    font-size: 17px;
    margin-bottom: 12px;
}

.text-small{
    font-family: 'museo-sans';
    font-weight: 300;
    font-size: 12px;
    line-height: 1.2;
}
</style>