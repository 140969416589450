<template>
  <div class="w-100">
      <!-- <h2 class="mb-3 text-light text-center" v-if="this.lang == 'pt'" >Versão com tradução em português</h2> -->
      <div class="d-flex video align-items-center text-white w-100">
          <iframe :src="src" frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  style="height: 420px;">
          </iframe>
      </div>
      <!-- <div v-if="this.lang != 'en' && live.login.config.state == 'private'" class="d-flex justify-content-end " > 
        <div class="emoji mx-md-5 mx-auto bg-white"><Emoji :liveId="live.id" /></div>
      </div> -->
      <!-- <h2 class="mb-3 mt-5 text-light text-center" v-if="this.lang == 'pt'" >Versão sem tradução</h2>
      <div class="d-flex video align-items-center text-white " v-if="this.lang == 'pt'">
          <iframe :src="src2" frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
          </iframe>
      </div> -->
  </div>
</template>

<script>
 import { mapState } from 'vuex';
// import Emoji from '@/components/Live/Emoji.vue'
export default {
  name: 'Video',
    data() {
        return {
            src: null,
            src2: null
        }
    },
    components: {
      // Emoji
    },
    computed: {
      ...mapState(['live', 'lang'])
    },
    methods: {
        setData() {
          switch (this.lang) {
            case 'pt':
              this.src = this.live.live.player.pt_url
              this.src2 = this.live.live.player.pt_url_noTranslate
              break;
            case 'esp':
              this.src = this.live.live.player.esp_url
              break;
            case 'en':
              this.src = this.live.live.player.en_url
              break;
          
            default:
              // this.src = this.live.live.player.url
              this.src = this.live.live.player.pt_url
              break;
          }
            
            // this.src = "https://player.vimeo.com/video/22195326?h=2f49fd7f8a&color=ffffff&title=0&byline=0&portrait=0"

        }
    },
    created() {
        this.setData();
    },
    watch: {
        live() {
            this.setData();
        }
    }
}
</script>

<style>
/* .video{
  position: absolute; 
   height: 500px; 
} */
iframe{
  border: none;
  width:100%;
  height:630px
}
@media(max-width: 1200px){
  iframe{height:530px}
}
@media(max-width: 991px){
  iframe{height:400px}
}
@media(max-width: 767px){
  iframe{height:290px}
}
@media(max-width: 523px){
  iframe{height:200px}
}
@media(max-width: 363px){
  iframe{height: 160px}
}
.emoji{
  /* position: relative; */
  z-index: 999;
  width: fit-content;
  margin-top: -10px;
  padding: 10px 30px; 
  border-radius: 50px;
}
h1{
    font-size: 50px;
    font-family: 'museo-sans-900';
}
</style>