<template>
  <footer :style="live.live.footer.styles" v-if="live!=null">
    <p class="textFooter mb-0 text-center">
      {{live.live.footer.texts[`${lang}`]}}
    </p>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
name: 'Footer',
props:['lang'],
computed: {
  ...mapState(['live'])
}
}
</script>

<style  scoped>
  .textFooter {
    /* color: #1EA69B; */
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    font-family: 'museo-sans';
  }

  @media(max-width:767px) {
      .textFooter {
        font-size: .9rem;
      }
  }   
</style>