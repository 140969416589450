<template>
  <div v-if="live" class="h-100">
    <iframe :src="src" width="100%" height="420px" frameborder="0" style="height: 420px;"></iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: "Chat",
    data(){
      return {
        src: null
      }
    },
    computed: {
      ...mapState(['live','lang'])
    },
    methods: {
      setData(){
        this.src = this.live.live.chat[`${this.lang}`]
      }
    },
    created() {
      this.setData();
    },
    watch: {
      live(){
        this.setData();
      }
    }
}
</script>

<style>

</style>