<template>
    <div v-if="carouselItems.length > 0" class="carousel mb-4">
        <img src="../../assets/img/banner.png" alt="Imagem ilustrativa">
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';

    export default {
        name: 'Caroussel',
        data() {
            return {
                slide: 0,
                sliding: null
            }
        },
        computed: {
            ...mapState(['live']),
            carouselItems() {
                console.log(this.live.live.carousel)
                return this.live.live.carousel;
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            }
        },
        watch: {
        
        }
    }
</script>

<style>
.carousel img{
        display: block;
        width: 100%;
}
</style>